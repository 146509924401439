import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite, CgDollar, CgPlayButton } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function WorkCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img className="image-card-work" style={{ objectFit:"cover"}} variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgPlayButton /> &nbsp; {"Play"}

          </Button>
        )}
        {"\n"}
        {"\n"}{"\n"}



      </Card.Body>
    </Card>
  );
}
export default WorkCards;
